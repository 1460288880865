$(document).ready(function () {
    $('.stngs-bdy-wrp .mr-stngs-head').click(function () {
        $(this).addClass('mr-head-active').parent().siblings().find('.mr-stngs-head').removeClass('mr-head-active');
    });


    // change the color of the dropdown selected text
    $('.cube-eng-drp-dwn-wdt ul li').click(function () {
        $(this).closest('.dropdown').addClass('cube-eng-drp-dwn-selected');
    });

    // add new group
    let headCountGrp = 1;
    $('.tmp-nw-grp-btn').click(function () {
        if ($('#aggr-group-id .nw-gp-spc-cls').length == 0) {
            headCountGrp = 0;
        }
        headCountGrp++;
        let newGroupTemplate = "<div class='new-grp-cont nw-gp-spc-cls'>\
                <div class='grp-cnt-blocks'>\
                    <div class='new-grp-head'><span class='grp-head-txt'>New Group "+ headCountGrp + "</span>\
                        <div class='del-btn-wrp'>\
                            <i class = 'icomoon icon-trash'></i>\
                            <span>Delete</span>\
                        </div>\
                    </div>\
                    <input class='mr-settings-input wdth-adj' type='input' placeholder='Add Max Dimension Combination' />\
                    <div class = 'mr-stngs-warn-msg'></div>\
                </div>\
                <div class='grp-cnt-blocks'>\
                    <div class='mr-flex-wrp'>\
                        <div class='mr-inner-head-txt tmp-dpdwn-opn'>Mandatory Fields\
                            <div class='mr-inner-head-dsc mr-hide-drg'>Sales_ID, Promotion_ID</div>\
                        </div>\
                        <div class='img-del-edt-tog-cont'><img class='edt-arr-toggle img-del-edt-tog' src='../../../assets/images/edit.svg' alt='' /><img class='dwn-arr-toggle' src='../../../assets/images/down-arrow.svg' alt='' /></div>\
                    </div>\
                    <ul class='grp-drp-dwn-lst'>\
                        <li class='select-li-txt'><label class='checkbox-container'>Country<input type='checkbox'/><span class='checkmark'></span></label></li>\
                        <li class='select-li-txt'><label class='checkbox-container'>State<input type='checkbox'/><span class='checkmark'></span></label></li>\
                        <li class='select-li-txt'><label class='checkbox-container'>City<input type='checkbox'/><span class='checkmark'></span></label></li>\
                        <li class='select-li-txt'><label class='checkbox-container'>Sales_ID<input type='checkbox'/><span class='checkmark'></span></label></li>\
                        <li class='done-btn'>Done</li>\
                    </ul>\
                </div>\
                <div class='grp-cnt-blocks nw-hier-to-dim add-hier-dyn'>\
                    <div class='cm-mr-link'>+ New Hierarchy To Dimensions</div>\
                </div>\
                <div class='grp-cnt-blocks nw-hier-to-dim nw-jnt-dim'>\
                    <div class='cm-mr-link'>+ New Joint Dimensions</div>\
                </div>\
            </div>\
        ";

        $(this).closest('.cm-settings-blocks').append(newGroupTemplate);
    });


    // show warning for the input field
    // $('.stngs-bdy-wrp-inner .mr-settings-input').on('keyup focusout', function () {
    $(document).on('keyup focusout', '.stngs-bdy-wrp-inner .mr-settings-input', function () {
        let _this = $(this);
        let $eleSelector = _this.parent().find('.mr-stngs-warn-msg');
        let warning = 'Please ';
        if (isEmpty(_this)) {
            warning += _this.attr('placeholder');
            addInvaildInput(_this);
            $eleSelector.text(warning);
        } else {
            removeInvaildInput(_this);
            $eleSelector.text('');
        }
    });
});

