var selectedCheckbox = [];
var orderedDropDwm = [];
$(document).ready(function () {

    $(document).on('click', '.new-grp-cont .dwn-arr-toggle, .new-grp-cont .edt-arr-toggle, .tmp-dpdwn-opn', function () {
        let $parentSelector = $(this).closest('.mr-flex-wrp');
        $parentSelector.find('.dwn-arr-toggle').toggleClass('mr-rot-180');
        $parentSelector.siblings().toggle();
    });


    $(document).on('click', '.del-ico-hier', function () {
        $(this).closest('.grp-cnt-blocks').remove();
    });

    $(document).on('click', '.edt-ico-hier', function () {
        $(this).addClass('mr-vis-hide-drg-drp');
        let $storeSelect = $(this).closest('.grp-cnt-blocks').children('.mr-stngs-drop-cont');
        $storeSelect.removeClass('mr-hide-drg-drp');
        let $parentSelector = $storeSelect.find('.mr-ad-dum');

        $parentSelector.each(function () {
            $(this).children('.mt-countr-btch').addClass('mr-hide-drg-drp');
            $(this).closest('.grp-cnt-blocks').append($(this));
        });

        let $eleSelector = $storeSelect.find('.drg-drp-cont');
        $eleSelector.children('.mt-countr-btch').text(1);
    });

    let headingCountJointDim = 0;
    $(document).on('click', '.nw-jnt-dim .cm-mr-link', function () {
        $(this).parent().hide();
        headingCountJointDim++;
        let tempToAdd = "<div class='grp-cnt-blocks nw-hier-to-dim-toggle'>\
                <div class='mr-flex-wrp'>\
                    <div class='mr-inner-head-txt'>Joint Dimensions "+ headingCountJointDim + "\
                        <div class='mr-inner-head-dsc'></div>\
                    </div >\
                    <img class='joint-dim-edt mr-hide-drg' src='../../../assets/images/edit.svg' alt='' />\
                </div >\
                <ul class='grp-drp-dwn-lst nw-grp-dymc-lst'>\
                    <li class='select-li-txt'><label class='checkbox-container'>Country<input type='checkbox' /><span class='checkmark'></span></label></li>\
                    <li class='select-li-txt'><label class='checkbox-container'>State<input type='checkbox' /><span class='checkmark'></span></label></li>\
                    <li class='select-li-txt'><label class='checkbox-container'>City<input type='checkbox' /><span class='checkmark'></span></label></li>\
                    <li class='select-li-txt'><label class='checkbox-container'>Sales_ID<input type='checkbox'/><span class='checkmark'></span></label></li>\
                    <li class='done-btn'>Done</li>\
                </ul>\
            </div>\
        ";

        $(tempToAdd).insertBefore($(this).parent());
    });

    let heirarchyCount = 0;
    $(document).on('click', '.add-hier-dyn .cm-mr-link', function () {
        if ($('.nw-gp-spc-cls .mr-tmp-hrchy-cnt').length == 0) {
            heirarchyCount = 0;
        }
        heirarchyCount++;
        let newDomCreated = blockCreator();
        let $hierarchyTemp = "<div class='grp-cnt-blocks mr-tmp-hrchy-cnt' >\
                <div class='mr-flex-wrp'>\
                    <h4 class='mr-inner-head-txt'>Hierachy "+ heirarchyCount + "\
                        <div class='mr-inner-head-dsc mr-mg-top-6 mr-hide-drg'>1. Country, 2. State, 3. City</div>\
                    </h4>\
                    <div class='mr-flex-wrp ic-cont-del'><img class='edt-ico-hier mr-vis-hide-drg-drp' src='../../../assets/images/edit.svg' alt='' /><img class='del-ico-hier' src='../../../assets/images/trash.svg' alt=''/></div>\
                    </div>\
                    <div class='mr-stngs-drop-cont'>\
                        <div class='mr-settings-input drg-drp-cont mr-full-wdth' ondrop='mr_drop_handler(event);' ondragover='mr_dragover_handler(event);'>\
                            <div class='mt-countr-btch'>1</div><span>Drag and drop the field</span></div>\
                    </div>\
                    "+ newDomCreated + "\
                </div >\
            ";
        $($hierarchyTemp).insertBefore($('.add-hier-dyn'));

    });

    $(document).on('click', '.nw-gp-spc-cls .done-btn', function () {
        checkboxCheckedValueFinder($(this));
        $(this).parent().siblings().find('.dwn-arr-toggle').toggleClass('mr-rot-180');
        let $parntSelect = $(this).closest('.grp-cnt-blocks');
        let innrTxt = convertArrayToString(selectedCheckbox);
        if (innrTxt != '') {
            if ($parntSelect.hasClass('mr-mand-flds')) {
                $('.img-del-edt-tog-cont img').toggleClass('img-del-edt-tog');
            } else {
                $parntSelect.find('.joint-dim-edt').removeClass('mr-hide-drg');
            }
            $parntSelect.find('.mr-inner-head-dsc').text(innrTxt);
            $(this).closest('.grp-cnt-blocks').find('.mr-inner-head-dsc').removeClass('mr-hide-drg');
        } else {
            $(this).closest('.nw-hier-to-dim-toggle').remove();
        }

        $(this).parent().hide();
        $('.nw-hier-to-dim').show();
    });

    $(document).on('click', '.joint-dim-edt', function () {
        $(this).parent().siblings().show();
    });


    // delete new group
    $(document).on('click', '.nw-gp-spc-cls .del-btn-wrp', function () {
        $(this).closest('.nw-gp-spc-cls').remove();
    });
});


function checkboxCheckedValueFinder($this) {
    selectedCheckbox = [];
    $this.closest('.grp-drp-dwn-lst, .cbd-drp-dwn-lst').find('input:checked').each(function () {
        selectedCheckbox.push($(this).parent('.checkbox-container').text());
    });

    return selectedCheckbox;
}


function convertArrayToString(selectedCheckbox) {
    let innrTxt = '';
    selectedCheckbox.forEach(function (val) {
        innrTxt += val + ', ';
    });
    return innrTxt.slice(0, -2);
}



// drag and drop function


function mr_dragstart_handler(ev) {
    // Add the target element's id to the data transfer object
    ev.dataTransfer.setData("text/plain", ev.target.id);
    ev.dropEffect = "move";
}
function mr_dragover_handler(ev) {
    ev.preventDefault();
    // Set the dropEffect to move
    ev.dataTransfer.dropEffect = "move"
}
function mr_drop_handler(ev) {
    ev.preventDefault();
    // Get the id of the target and add the moved element to the target's DOM
    let data = ev.dataTransfer.getData("text/plain");
    let $eleSelector = $('#' + data).closest('.grp-cnt-blocks').find('.drg-drp-cont');
    $('#' + data).insertBefore($eleSelector);
    orderDroppedCont(data);
}

function orderDroppedCont(data) {
    let _this = $('#' + data);
    orderedDropDwm = [];
    let $eleSelector = _this.closest('.grp-cnt-blocks');
    $eleSelector.find('.edt-ico-hier').removeClass('mr-vis-hide-drg-drp');
    let $childSelector = $eleSelector.find('.mr-stngs-drop-cont');
    $tempSelect = $childSelector.find('.mr-settings-input');
    $tempSelect.each(function (ind) {
        let $contSelector = $(this).find('.mt-countr-btch');
        $contSelector.removeClass('mr-hide-drg-drp');
        $contSelector.text(ind + 1);
        if (!$childSelector.next().hasClass('mr-settings-input')) {
            $childSelector.addClass('mr-hide-drg-drp');
        } else {
            $childSelector.removeClass('mr-hide-drg-drp');
        }
        let cnvStr = (ind + 1) + '. ' + $(this).find('span').text().trim();
        orderedDropDwm.push(cnvStr);
    });
    orderedDropDwm.pop();

    let innrTxt = '';
    innrTxt = convertArrayToString(orderedDropDwm);

    $eleSelector.find('.mr-inner-head-dsc').text(innrTxt).removeClass('mr-hide-drg');

    orderedDropDwm.length = 0;
    delete _this;
    delete $eleSelector;
    delete $contSelector;
    delete $childSelector;
}

let tempind = 0;

function blockCreator() {
    let iterateDomEle = '';
    selectedCheckbox.forEach(function (val) {
        iterateDomEle += "<div class = 'mr-settings-input mr-full-wdth mr-ad-dum'\
        id = "+ tempind++ + "\
        draggable = 'true'\
        ondragstart = 'mr_dragstart_handler(event);' >\
            <div class='mt-countr-btch mt-countr-btch-atv mr-hide-drg-drp'>0</div>\
            <span> "+ val + " </span>\
            </div >\
        ";
    });
    return iterateDomEle;
}



