function insightPageController() {
    $(document).ready(function () {

        // tab switching
        $('.insights-body-header-ul li').click(function () {
            $(this).addClass('mr-active-tab').siblings().removeClass('mr-active-tab');
            const selectedTab = $(this).index();
            if (selectedTab == 2) {
                $('.insights-view-query-wrp, .insights-bdy').removeClass('query-execiting').addClass('query-history-selected');
                $('.txt-content').attr('readonly', true);
            } else if (selectedTab == 1) {
                $('.txt-content').attr('readonly', true);

            } else {
                $('.txt-content').attr('readonly', false);
                $('.insights-bdy').removeClass('query-execiting query-history-selected');
            }
        });

        $('.insights-query-hist-tab .icon-wrp .img-drop-dwn').click(function () {
            $(this).toggleClass('mr-trans-rot');
        });


        // check query is entered or not and make the submit button active
        $('.insights-bdy-content-wrp .txt-content').keyup(function () {
            if ($(this).val().length > 0) {
                $('.insights-bdy-bottom-btn-wrp .primary-inactive').addClass('primary-active').removeClass('primary-inactive');
            } else {
                $('.insights-bdy-bottom-btn-wrp .primary-active').addClass('primary-inactive').removeClass('primary-active');
            }
        });


        // execute the query by clicking submit button
        $('.insights-bdy-bottom-btn-wrp .mr-btn-primary').click(function () {
            $('.insights-view-query-wrp').addClass('query-execiting');
        });


        // show the table by clicking the dropdown arrow
        $('.attempt-stat-wrp .arrow-right').click(function () {
            $(this).toggleClass('mr-trans-rot');
            $(this).parent().siblings().toggle();
        });


        // delete the table row
        $('.insights-body-wrap .mr-del-icon').click(function () {
            $(this).closest('tr').remove();
        });

    });
}
