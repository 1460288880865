$(document).ready(function () {
    $('.code-category li').click(function () {
        $(this).addClass('mr-act-tb').siblings().removeClass('mr-act-tb');
        let ind = $(this).index() + 1;
        $(this).closest('.accr-drp-dwn').children().eq(ind).removeClass('mr-hide').siblings('div').addClass('mr-hide');
    });

    // $('.accr-btn').click(function () {
    //     $(this).siblings().toggleClass('mr-hide');
    // });
});