$(document).ready(function () {
    $('.tmp-adv-dic-lnk').on('click', function (e) {
        $(this).closest('tr').prev('.mr-rw-hide').show();
        $('.mr-rw-hide .dropdown-menu').each(function () {
            $(this).closest('.dropdown').children('.mr-btn-dropdown').text($(this).children('li:first-child').text());
        });

        $(".temp-checkbx-clk input").prop("checked", false);
        e.preventDefault();
        e.stopPropagation();
        return false;
    });

    $('.stngs-bdy-tble-body .mr-sub-act').on('click', function (e) {
        let $rwSelector = $(this).closest('tr');
        let valueArr = [];
        valueArr = rowValueExtractor($rwSelector);

        let $template = '';
        $template = "<tr>\
                <td class='fi-dta'>" + valueArr[0] + "</td>\
                <td class='sec-dta'>" + valueArr[1] + "</td>\
                <td class='thi-dta'>"+ valueArr[2] + " </td>\
                <td>\
                    <div class='mr-flex-wrp icon-con-wdth'>\
                        <img class= et-rw src='../../../assets/images/edit.svg', alt='' />\
                        <img class= del-rw src='../../../assets/images/trash.svg', alt='' />\
                    <div>\
                </td>\
            </tr>\
            <tr class='mr-rw-hide mr-rw-hide-temp'>\
                <td>\
                    <div class='cm-mr-drop-dwn wdth-adv-dic'>\
                        <li class='dropdown'>\
                            <div class='dropdown-toggle mr-btn-dropdown' role='button' data-toggle='dropdown' aria-expanded='false'>Promotion_ID</div>\
                            <img class='mr-drop-down-img' src='../../../assets/images/down-arrow.svg' alt=''>\
                            <ul class='dropdown-menu' >\
                                <li>Promotion ID</li>\
                                <li>MapReduce</li>\
                                <li>demo text</li>\
                                <li>demo text</li>\
                            </ul>\
                        </li>\
                    </div>\
                </td>\
                <td>\
                    <label class='checkbox-container adv-dic-ckbx'>\
                    <input type='checkbox'><span class='checkmark'></span></label>\
                    <div class='cm-mr-drop-dwn wdth-adv-dic'>\
                        <li class='dropdown'>\
                            <div class='dropdown-toggle mr-btn-dropdown' role='button' data-toggle='dropdown'>Global Dictionary</div><img class='mr-drop-down-img' src='../../../assets/images/down-arrow.svg' alt=''>\
                            <ul class='dropdown-menu'>\
                                <li>Global Dictionary</li>\
                                <li>MapReduce</li>\
                                <li>demo text</li>\
                                <li>demo text</li>\
                            </ul>\
                        </li>\
                    </div>\
                </td >\
            <td>\
                <label class='checkbox-container adv-dic-ckbx'>\
                <input type='checkbox'><span class='checkmark'></span></label>\
                <div class='cm-mr-drop-dwn wdth-adv-dic'>\
                    <li class='dropdown'>\
                        <div class='mr-btn-dropdown'>Sales_Promotion </div>\
                    </li>\
                </div>\
                </td>\
                <td><button class='mr-btn-tertiary btn-active mr-sub-act-temp'>Submit</button></td>\
            </tr>\
        ";

        delete valueArr;
        $($template).insertBefore($rwSelector);
        $rwSelector.hide();

        // for hiding the edit row
        let $rwSelectorNext = $rwSelector.next();
        if ($rwSelectorNext.hasClass('mr-rw-hide-temp')) {
            $rwSelectorNext.hide();
        }
        e.preventDefault();
        e.stopPropagation();
        return false;
    });

    $(document).on('click', '.mr-rw-hide-temp .dropdown-menu li', function () {
        $(this).closest('.dropdown').children('.mr-btn-dropdown').text($(this).text());
    });

    $(document).on('click', '.stngs-bdy-tble-body .del-rw', function () {
        let $rwSelector = $(this).closest('tr');
        $rwSelector.next().remove();
        $rwSelector.remove();
    });

    $(document).on('click', '.stngs-bdy-tble-body .et-rw', function () {
        let $rwSelector = $(this).closest('tr').next();
        $rwSelector.show();
    });

    $(document).on('click', '.stngs-bdy-tble-body .mr-sub-act-temp', function () {
        let $rowSelector = $(this).closest('tr');
        let valArr = rowValueExtractor($rowSelector);
        $rowSelector.prev().find('.fi-dta').text(valArr[0]);
        $rowSelector.prev().find('.sec-dta').text(valArr[1]);
        $rowSelector.prev().find('.thi-dta').text(valArr[2]);
        $rowSelector.hide();
        delete valArr;
    });
});


function rowValueExtractor($tbodySelector) {
    let valueObj = [];
    valueObj.push($tbodySelector.find('td:first-child').find('.mr-btn-dropdown').text());
    valueObj.push($tbodySelector.find('td:nth-child(2)').find('.mr-btn-dropdown').text());
    let $rowSelected = $tbodySelector.find('td:nth-child(3)');
    let boxclicked = true;
    $rowSelected.find('input:checked').each(function () {
        boxclicked = false;
        valueObj.push('Sales Promotion');
    });

    if (boxclicked) {
        valueObj.push('-');
    }

    return valueObj;
}