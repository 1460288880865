$(document).ready(function () {

    $('.rf-stngs-inp-wrp input').on('keyup focusout', function (e) {
        let warnTxt = "Please "
        let _this = $(this);
        if (isEmpty(_this)) {
            warnTxt += _this.attr("placeholder");
            addWarning(_this, warnTxt);
        } else {
            if (!_this.next().hasClass('mr-btn-tertiary')) {
                if (!dropDownSelectedCheck(_this.next())) {
                    warnTxt = "Please select the dropdown";
                    addWarning(_this, warnTxt);
                } else {
                    removeWarning(_this);
                }
            } else {
                removeWarning(_this);
            }
        }
    });

    $('.auto-mrg-cls-icon, .auto-mrg-edt-icon').click(function () {
        $(this).closest('.auto-mrg-thd-cont').addClass('auto-mrg-thd-hide');
    });


    // dropdown selector
    $('.refresh-stngs-wrp .dropdown-menu li').click(function () {
        let $eleSelector = $(this).closest('.cm-mr-drop-dwn');
        let _this = $(this).closest('.cm-mr-drop-dwn').siblings('input');
        if (!isEmpty(_this)) {
            removeWarning($eleSelector);
        }
    });

    $('.auto-merge-threshold-submit, .volatile-range-submit, .retention-threshold-submit').click(function () {
        if ($(this).hasClass('btn-active')) {
            // success
            let $eleSelector = $(this).siblings('.auto-mrg-thd-cont')
            $eleSelector.removeClass('auto-mrg-thd-hide');
            let enteredValue = $(this).siblings('.mr-settings-input').val();
            enteredValue += ' ' + $(this).siblings('.cm-mr-drop-dwn').find('.mr-btn-dropdown').text();
            $eleSelector.children('.no-of-days').text(enteredValue);
        }
    });

    // for datepicker

    $('.datepicker-ref input').datepicker({
        startDate: new Date(),
        format: 'dd/mm/yy',
        todayHighlight: true,
        forceParse: true,
        autoclose: true,
        toggleActive: true
    }).on('change', function (ev) {
        $(this).datepicker('hide');
    });

    $('.mr-cal-img').click(function () {
        $(this).siblings('.datepicker-ref').find('input').datepicker('show');
    });
});

function isEmpty(_this) {
    if ((_this).val() != '') {
        return false;
    } else {
        return true;
    }
}

function dropDownSelectedCheck(_this) {
    if (_this.children().hasClass('cube-eng-drp-dwn-selected')) {
        return true;
    }
    return false;
}

function invalidInputCheck(_this) {

}

function addWarning(_this, warn) {
    let $eleSelector = _this.closest('.rf-stngs-inp-wrp');
    $eleSelector.find('button').removeClass('btn-active').addClass('btn-inactive');
    $eleSelector.next().text(warn);
    addInvaildInput(_this)
}

function removeWarning(_this) {
    let $eleSelector = _this.closest('.rf-stngs-inp-wrp');
    $eleSelector.find('button').removeClass('btn-inactive').addClass('btn-active');
    $eleSelector.next().text('');
    removeInvaildInput(_this);
}

function addInvaildInput(_this) {
    _this.closest('.cm-settings-blocks').addClass('invalid-input');
    _this.addClass('invalid-input-field');
}

function removeInvaildInput(_this) {
    _this.closest('.cm-settings-blocks').removeClass('invalid-input');
    _this.removeClass('invalid-input-field');
}
