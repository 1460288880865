$(document).ready(function () {
    $('.cube-setting-menu-wrp .mr-settings-wrp').click(function (e) {
        if (!$(this).hasClass('mr-settings-active')) {
            $(this).addClass('mr-settings-active').siblings().removeClass('mr-settings-active');
            $('.cube-setting-menu-wrp .stngs-lst-wrp').slideUp();
        }
        $(this).next().slideDown();
        let ind = $(this).index() / 2;
        $('.stngs-bdy-wrp-inner .mr-stngs-cont').eq(ind).addClass('mr-stngs-hide').siblings().removeClass('mr-stngs-hide');
        e.preventDefault();
    });

    let windwHeight = $(window).outerHeight();
    $('.stgs-bdy-wrp').height(windwHeight - 178);

    $('.stngs-lst-wrp a[href^="#"]').click(function (e) {
        e.stopPropagation();
        let attrSelector = $(this).attr('href');
        $(this).parent().addClass('mr-list-active').siblings().removeClass('mr-list-active');
        $('.stngs-bdy-wrp-inner ' + attrSelector + '> .mr-stngs-head').addClass('mr-head-active');
        $('.stngs-bdy-wrp-inner' + attrSelector).siblings().find(".mr-stngs-head").removeClass('mr-head-active');

        let heightCalc = 0;
        var count = 0;
        $('.mr-stngs-hide > .cm-settings-blocks').each(function () {
            let id = '#' + $(this).attr('id');
            if (count == 0) {
                if (id == attrSelector) {
                    count = 1;
                } else {
                    heightCalc += $(this).outerHeight();
                }
            }
        });

        $('.stgs-bdy-wrp').animate({ scrollTop: heightCalc }, 1000);
        return false;
    });


});