$(document).ready(function () {
    $('.conf-override-wrp .cm-mr-link').click(function () {
        $(this).closest('.new-grp-cont').children('.mr-flex-wrp').removeClass('tmp-inp-wrp');
        $(this).parent().prev().find('.prop-inp').val('');
    });


    $(document).on('keyup', '.prop-inp', function () {
        if (checkInputFieldFilled($(this))) {
            buttonActivator();
        } else {
            $('.tmp-btn-cnt, .tmp-dyn-btn-cnt').addClass('btn-inactive').removeClass('btn-active');
        }
    });


    $(document).on('click', '.tmp-btn-cnt', function () {
        if ($(this).hasClass('btn-active')) {
            $(this).closest('.mr-flex-wrp').addClass('tmp-inp-wrp');
            createPropertyFunction($(this));
        }
    });

    $(document).on('click', '.tmp-dyn-btn-cnt', function () {
        if ($(this).hasClass('btn-active')) {
            $(this).closest('.mr-flex-wrp').addClass('tmp-dyn-inp-wrp');
            editPropertyFunction($(this));
        }
    });

    $(document).on('click', '.prop-edt-icon', function () {
        $(this).closest('.mr-cnt-blocks').next().removeClass('tmp-inp-wrp tmp-dyn-inp-wrp');
    });

    $(document).on('click', '.prop-cls-icon', function () {
        $(this).closest('.mr-cnt-blocks').parent().remove();
    });
});

function editPropertyFunction(_this) {
    let $parentSelector = _this.closest('.mr-drn-ad-con')
    $parentSelector.find('.prop-dynm-key').text(_this.siblings('.prop-inp-key').val())
    $parentSelector.find('.prop-dynm-val').text(_this.siblings('.prop-inp-val').val())
}

function createPropertyFunction(_this) {
    let keyEntered = _this.siblings('.prop-inp-key').val();
    let valEntered = _this.siblings('.prop-inp-val').val();
    let template = "<div class = 'mr-drn-ad-con'>\
            <div class = 'mr-cnt-blocks mr-flex-wrp'>\
                <div class = 'left-wrp mr-flex-wrp'>\
                    <div class = 'prop-dynm-key'>"+ keyEntered + "</div>\
                    <div class = 'prop-dynm-val'>"+ valEntered + "</div>\
                </div>\
                <div class='mr-flex-wrp icon-con-wdth'>\
                    <img class='prop-edt-icon' src='../../../assets/images/edit.svg', alt=''>\
                    <img class='prop-cls-icon' src='../../../assets/images/trash.svg', alt=''>\
                </div>\
            </div>\
            <div class='mr-cnt-blocks mr-flex-wrp tmp-dyn-inp-wrp'>\
                <input class='mr-settings-input prop-inp prop-inp-key' type='text' value = "+ keyEntered + ">\
                <input class='mr-settings-input prop-inp prop-inp-val' type='text' value = "+ valEntered + ">\
                <button class='mr-btn-tertiary btn-active tmp-dyn-btn-cnt'>Submit</button>\
            </div>\
        <div>\
    ";

    $(template).insertBefore(_this.parent());
}


function buttonActivator() {
    if (checkAllInputFieldFilled()) {
        $('.tmp-btn-cnt, .tmp-dyn-btn-cnt').removeClass('btn-inactive').addClass('btn-active');
        return true;
    } else {
        $('.tmp-btn-cnt, .tmp-dyn-btn-cnt').addClass('btn-inactive').removeClass('btn-active');
        return false;
    }
}


function checkInputFieldFilled(_this) {
    if (_this.val().length > 0) {
        return true;
    }
    return false;
}

function checkAllInputFieldFilled() {
    let cntVal = 0;
    $('.prop-inp').each(function () {
        if (!checkInputFieldFilled($(this))) {
            cntVal++;
        }
    });
    if (cntVal == 0) {
        return true;
    } else {
        return false;
    }
}